import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'


const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 700px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`

const Title = styled.h2 `
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0.5em;
    font-size: 1.75rem;
`

const StyledFigure = styled.figure `
    margin: 1.5rem 0 2rem;
    display: grid;
    justify-content: center;

    img {
         max-height: 440px;
    }

    ${({doubleImage}) => `${doubleImage && `
        @media ${breakpoint.sm} {
            grid-template-rows: 440px auto;
        }
    `}
    `}


    @media ${breakpoint.md} {
        margin: 2rem 0 2.5rem;
    }

   /*  img {
        max-height: 440px;
        width: auto;
    } */
`

const StyledFigcaption = styled.figcaption `
    font-size: 0.875rem;
    color: gray;
    margin-top: 0.5em;
    text-align: right;
`

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  margin-bottom: 2.5rem;
  display: inline-block;
  font-size: 0.875rem;
  transition: color 0.3s ease;

  @media ${breakpoint.lg} {
    margin-bottom: 4rem;
  }

  &:hover {
    color: ${colors.orangeHover};
  }

  .icon {
    margin-right: 0.5rem;
  }
`

const Date = styled.p`
    color: gray;
    margin: 0;
    font-size: 0.875rem;
`

const BlogList = styled.ol `
     padding: 0;
     list-style: none;
    counter-reset: my-awesome-counter;

    h3 {
        font-size: 1rem;
        padding-left: 1.25em;
        margin-bottom: 0.5em;
        font-weight: 700;
    }
    p {
        display: inline;
    }

    li {
        margin: 1.5rem 0;
        line-height: 1.5;
        counter-increment: my-awesome-counter2;
        position: relative;

        &::before {
            content: counter(my-awesome-counter2) "." ;
            font-weight: 700;
            position: absolute;
        }

        ul li::before {
            content: normal;
        }
    }
`
const DoubleImage = styled.div `
    margin: 1.5rem 0 2.5rem;

    @media ${breakpoint.sm} {
        display: grid;
     gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);
    }
`

const Author = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  opacity: 0.75;
`

const Info = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0 0.5rem;
  margin-bottom: 2rem;
`

const SectionTitle = styled.h3`
    padding-top: 1em;
`

const BlogpostPage = ({data, title}) => {

    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
            <BackLink to="/blog">
                <span className="icon icon-arrow-left"></span>
                Zpět na výpis článků
            </BackLink>
            <Title>Camino - o samotě</Title>
            <Info>
                <Author>Julia</Author>
                <Date>23. 4. 2023</Date>
            </Info>
            <p>Nejčastěji pokládanou otázkou na Caminu je - <b>proč jsi se rozhodl/a Camino jít?</b> Posledních pár let bylo pro mě ve znamení velkých změn - několikrát jsem se stěhovala, vyzkoušela jsem si, jaké to je odjet žít sama do jiné země, několikrát jsem změnila práci, několikrát se rozešla a zase se zamilovala, přišla o toho nejcennějšího člověka ve svém životě - tátu, nastěhovala si k sobě 7 členů své rodiny v důsledku války na Ukrajině a mohla bych pokračovat ještě dál.</p>
            <p>Někdo by řekl, že jsem se nenudila. Rozhodně ne. Každopádně jsem potřebovala být chvíli sama se sebou, zjistit co vlastně chci, jakou cestou se vydat a prostě jen být sama pro sebe.</p>
            <p>O Caminu jsem už několikrát slyšela a zdál se mi to skvělý způsob, jak si protřídit myšlenky a strávit čas o samotě.</p>
            <p>Nakonec z toho vzešlo ještě něco mnohem většího, než jsem mohla tušit.</p>
            <StaticImage src="../assets/images/blog/camino/camino1.jpg" alt="Příprava na cestu"></StaticImage>
            <SectionTitle>Den 1 - příjezd</SectionTitle>
            <p>Po příletu do Porta jsem se vydala do katedrály pro svůj poutnický Credencial - pas poutníka. Credencial slouží poutníkům ke sbírání razítek během cesty. Nejčastěji jsou k získání v navštívených kostelech, kavárnách, restauracích a albergues (poutnických ubytovnách).</p>
            <StaticImage src="../assets/images/blog/camino/camino2.jpg" alt="Porto"></StaticImage>
            <p>Ubytovala jsem se v <a href="https://www.booking.com/Share-1oUj7n">Sandeman Hostel</a> a vyrazila na druhý břeh řeky do centra na nějaké jídlo. Těšila jsem se na portské víno a tapas. Našla jsem si hezké místo s výhledem na řeku a město. Vychutnávala jsem si dobré jídlo,  víno a chvíle,  kdy jsem nevěděla, co bude následovat.</p>
            <StaticImage src="../assets/images/blog/camino/camino3.jpg" alt="Restaurace v Portu"></StaticImage>
            <p>Při placení jsem zjistila, že nemám peněženku v ledvince, která tam však být měla. Začala jsem trošku vyšilovat - musí tam být. Na hostelu jsem si rozdělila peníze, většinu jsem nechala ve velkém batohu a zbytek sil dala do peněženky a tu jsem dala do ledvinky. Teď tam není, kde je?</p>
            <p>Rychlochůzí jsem vyrazila zpět na hostel, třeba jsem si ji nechala na posteli? Ne, bohužel. Nebyla tam. No nic, rychle jsem zavolala do banky a nechala si zablokovat platební kartu. Následně jsem zjistila, že karta byla mezitím už 3x použita, a to určitě ne mnou. V bance mi řekli, že až se platby zaúčtují, vyřídím si online reklamaci a peníze mi vrátí zpět. Super! Každopádně v mobilním bankovnictví jsem zjistila adresu jednoho z míst, kde byla karta použita.</p>
            <p>Rozhodla jsem se tam zajít. Byl to obchůdek se suvenýry a nápoji, prodavač měl schované kopie účtenek od všech plateb kartou. Mou kartou byly zaplacené 2 Coca Coly. Super, snad si dotyčný pochutnal. Nemáš zač.</p>
            <p>Vydala jsem se tedy na policii, jelikož jsem měla v peněžence mimo jiné také OP. Na jedné ze stanic v centru mě vyslechli a poslali na policii pro turistické záležitosti, co mě však překvapilo, že jsou otevřeni až od půlnoci. Dobrá tedy, počkala jsem a vyrazila.</p>
            <p>Nejdříve se mě chtěli zbavit - ano, na policii pro turisty nikdo nemluvil anglicky. Zajímavé. Jenže já jsem se nenechala odbýt. Vyndala jsem telefon, a řekla, že si pomůžeme překladačem. Sdělila, že jiný den tam přijít opravdu nemůžu, protože ráno vyrážím do Santiaga. Policista se na mě podíval, zasmál se, a zmínil, že by tam taky chtěl jednou vyrazit. Sepsali jsme protokol a já mohla odjet zpět na hotel a pár hodin se vyspat.</p>
            <SectionTitle>Den 2 - Vyrážím</SectionTitle>
            <p>Ráno jsem vstala v 6 a v půl 7 už jsem byla na cestě. V hostelu jsem dostala do sáčku jablko, croissant a kafe. Snídaně šampionů.</p>
            <StaticImage src="../assets/images/blog/camino/camino4.jpg" alt="Julia v Portu"></StaticImage>
            <p>Jediná věc, která mě trápila úplně nejvíce - jdu do Santiaga a nemám žádný doklad totožnosti. O občanku jsem přišla, pas mám doma v ČR v šuplíku, v kapse 100 EUR. Pomyslně se uchlácholuji druhou kartou v telefonu. Zůstat tam a vydat se do Lisabonu na ambasádu však nepřipadalo v úvahu. Já totiž jdu do Santiaga.</p>
            <p>Ranní Porto bylo úžasné - žádné lidé, prázdné ulice, příjemné letní počasí a já plná nadšení z toho, že se to děje. První kilometry vedly městem, pak kolem polí, letiště a dále přes různě malé vesničky. Zatím jsem neviděla žádné poutníky. Lidé, které jsem potkávala, se vždy usmáli a popřáli “Bon camina”, což znamená v překladu “Šťastnou cestu”.</p>
            <DoubleImage>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/camino/camino5.jpg" alt=""></StaticImage>
                </StyledFigure>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/camino/camino6.jpg" alt=""></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <p>Po cestě jsem měla několik zastávek. Některé byly na kafe, doplnění vody, jiné zase k návštěvě kostela nebo nějakého bistra pro svačinu a návštěvě toalety.</p>
            <p>Musím říct, že to utíkalo - těšila jsem se z toho, že se to děje. Zároveň mě napadla skvělá věc. Napadlo mě, že kdyby můj pas, který byl v ČR v šuplíku, se dostal do Santiaga, než tam dojdu, tak mohu odletět v klidu domů. Na poslední noc v Santiagu jsem si teda zarezervovala menší hotýlek. Následně zmobilizovala kamarádku Barču doma a požádala ji o zaslání mého pasu na adresu hotýlku do Santiaga. Hotel jsem o tom informovala a čekala, až mi dají vědět, že mají můj pas.</p>
            <p>Moje první zastávka byla ve městečku Vila Cha, kam jsem dorazila okolo půl třetí odpoledne. Ubytovala jsem se v Albergue a po sprše se seznámila s ostatními poutníky - bylo nás tam celkem asi 9, z toho 6 Čechů - vcelku zajímavá náhoda. Byli jsme tam z různých koutů ČR - Praha, České Budějovice, Ostrava, Havířov a různého věku - od 18 do 30 let. Zbytek večera jsme pak strávili společně - zašli jsme si k oceánu a na večeři.</p>
            <StaticImage src="../assets/images/blog/camino/camino7.jpg" alt=""></StaticImage>
            <SectionTitle>Den 3 - Cesta podél pobřeží </SectionTitle>
            <p>Ráno jsem vyrazila okolo 7:00. Cesta vedla podél pobřeží po dřevěných prknech. Mohla jsem sledovat nádherné výhledy na oceán, před sebou periferie měst a vychutnávat si zvuky oceánu. Během první hodiny jsem dostala zprávu, že jeden z Čechů, který vyrážel po mně, má u sebe pas jedné z Češek, co vyrazila chvíli přede mnou. Vzájemně jsme se tedy zkontaktovali a všichni se sešli během oběda v jednom městečku, kde zároveň došlo k navracení zapomenutého pasu.  Následně jsme se během dne ještě párkrát potkali - ať už někde po cestě nebo v nějaké z kaváren.</p>
            <StaticImage src="../assets/images/blog/camino/camino8.jpg" alt=""></StaticImage>
            <p>Mým cílem bylo dojít do města Fao, kde jsem ihned na začátku města zůstala přes noc. Pomocí aplikace booking jsem si tu rezervovala noc v kempu - měli zde jednu budovu, která fungovala jako hostel. Tři menší pokoje a v každém pokoji 2 lůžka (palanda). Měla jsem štěstí - ve svém pokoji jsem byla sama.</p>
            <DoubleImage>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/camino/camino9.jpg" alt=""></StaticImage>
                </StyledFigure>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/camino/camino10.jpg" alt=""></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <p>Tento den jsem již plně pocítila bolest - nejvíce mě bolela chodidla a párkrát se ozvaly kyčle (opravdu jen lehce). Největší problém byl opravdu s chodidly, měla jsem pocit, že je mám v jednom ohni. Měla jsem za sebou dalších cca 30 km.</p>
            <p>V místním bistru v kempu jsem si dala večeři, dvě malé piva a šla spát opravdu brzy. To jsem ještě netušila, že mě ve 02:00 ráno probudí 2 slečny z vedlejšího pokoje, které zrovna dorazily z večírku a rozhodly se rozsvítit celou místnost včetně pokojů.</p>
            <p>Z vedlejšího pokoje pak je jen někdo velmi důrazně požádal o klid a ticho a ať se okamžitě uloží. Naštěstí výzvu uposlechly bez připomínek :)</p>
            <SectionTitle>Den 4 - Proč si nekoupit šaty</SectionTitle>
            <p>Tentokrát jsem byla na cestě již od 06:20. Viděla jsem předpověď počasí a tušila, že bude opravdu velké teplo a mě čekalo opět 30 km, tudíž jsem musela vyrazit o něco dříve.</p>
            <DoubleImage>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/camino/camino11.jpg" alt=""></StaticImage>
                </StyledFigure>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/camino/camino12.jpg" alt=""></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <p>Po hodince jsem se zastavila v kavárně, což je opravdu oříšek, potkat zde něco otevřeného takhle brzy ráno. Využila jsem to k pauze na kafe a nechala si udělit další razítko.</p>
            <p>Začátek cesty byl úchvatný - prázdné centrum a hlavní promenáda, všude klid. Následně mě čekala cesta lesy a trocha převýšení. Měla jsem během dne několik krizí - pálení chodidel, šílené vedro a sem tam kyčle. Po cestě jsem se potkala s několika dalšími poutníky - z Kanady, USA a opět z ČR.</p>
            <DoubleImage>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/camino/camino13.jpg" alt=""></StaticImage>
                </StyledFigure>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/camino/camino14.jpg" alt=""></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <p>Když jsem dorazila do Viana do Castelo, zamířila jsem rovnou do Albergue v kostele, který  jsem našla v letáčku o Caminu. Toto Albergue se nedalo dopředu rezervovat - kdo dřív přijde, ten má kde spát. Ihned u dveří jsem se potkala s poutníkem z Holandska, který již byl v Santiagu a byl zrovna na cestě zpět do Porta. Hlavou mi proletělo jen, ”blázen”, ale to jsem netušila, že až dokončím pouť do Santiaga, budu chůzí posedlá.</p>
            <p>Každopádně jsem se ubytovala v přiděleném pokoji s dalšími 4 lidmi - španělský manželský pár a kanadský pár, který jsem již potkala během dne na cestě. Paní ze Španělska byla zdravotní sestřička, když jsem se zmínila, že mám chodila v jednom ohni - ihned mi podala lahvičku s nějakou tekutinou. Netuším, co to bylo, ale považuji to do dnes za zázrak. Nohy mi to zklidnilo a zchladilo. To byla slast!</p>
            <p>Vyrazila jsem pak do města, abych prozkoumala centrum. V uličkách jsem narazila na malý butik, kde ve výloze měli nádherné lehoučké letní šaty, které mi přímo říkaly: “Pojď si mě zkusit, Julčo”. OK, nenechala jsem se dlouho přemlouvat a najednou jsem stála ve zkušební kabince v těch nádherných šatech, které jsem tam nemohla nechat. Teď si asi říkáte, že jsem asi blázen - kdo si koupí šaty na pouti… Já! Nejšílenější na tom bylo, že za šaty jsem musela zaplatit hotově 15 EUR a pak už mi zbyla poslední hotovost - 20 EUR. Výběr byl nemožný, jelikož v Portugalsku nejsou bezkontaktní bankomaty.</p>
            <DoubleImage>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/camino/camino15.jpg" alt=""></StaticImage>
                </StyledFigure>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/camino/camino16.jpg" alt=""></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <p>S plným břichem a novými šaty jsem došla na Albergue. Chvíli jsem si povídala se svými spolubydlícími na pokoji, kde jsem se dozvěděla, jak si zpříjemnit spaní během velkých veder v létě. Postarší pán z Kanady na to měl jasný recept - vlažná sprcha v noci a bez utření si lehnout rovnou do postele. Nejdříve mě to pobavilo, ale o pár týdnů později během tropických nocí v ČR jsem si na to vzpomněla, a jako když najdeš.</p>
            <SectionTitle>DEN 5 - Dneska si dám méně kilometrů</SectionTitle>
            <p>Plán byl jasný - dneska si dám méně kilometrů a svou cestu ukončím v Moledo, což je 5 km před městem Caminha, kde se cesta dělí a poutník má dvě možnosti. První možností je vydat se lodí přes řeku do Španělska (zde lze použít uznaný dopravní prostředek k přepravě - převozní taxi) a nebo druhá možnost - vydat se pěšky podél řeky Minho do města Valenca a tam přejít přes most do španělského města Tui.</p>
            <p>Na cestě jsem byla opět od půl sedmé. Hodně mě trápila bolest chodidel. Nejhorší vždycky byl úsek po jakékoliv pauze. Když už jsem se rozchodila, bylo to v pohodě, ale jakmile jsem si dala pauzu a zase se rozhodla jít, připadalo mi, že jdu rychlostí želvy.</p>
            <DoubleImage>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/camino/camino17.jpg" alt=""></StaticImage>
                </StyledFigure>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/camino/camino18.jpg" alt=""></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <p>První úsek cesty jsem si nasadila sluchátka a rozhodla se si zazpívat. Kdo kolem mě prošel, mohl slyšet Oy u luzi Chervona Kalyna (ukrajinská národní píseň) v mém podání. Když jsem procházela lesem, našla jsem si dva klacky, které jsem používala místo trekových holí. Hodně mi to pomáhalo při chůzi si ulevit a přenést váhu z nohou také na ruce.</p>
            <p>V tomto úseku je vcelku dost převýšení a klesání. Musím říct, že klesání bylo vždy nejhorší - chodidla jsem cítila ještě jednou tolik. Co se musí však nechat, to byly výhledy, které člověk mohl spatřit, když byl na kopcích.</p>
            <p>Měla jsem před sebou poslední kilometr před cílem. Sluníčko pálilo více a více a já se těšila, až konečně dorazím do Albergue, dám si sprchu a nohy nahoru. Zklamání přišlo ihned poté, co jsem dorazila do Moledo. Byly zde sice 2 Albergue (hostely), ale nebylo už žádné místo. Zkoušela jsem ještě najít něco na Google i na Bookingu, ale nejlevnější možnost by mě vyšla na cca 100 EUR na noc. Byla jsem sice hodně unavená a vyřízená ze sluníčka, ale ne natolik, abych dala 100 EUR za noc (byla to možnost pokoje v hotelu). Tudíž mé nadšení z posledního kilometru vystřídalo zklamání, že mě čeká ještě dalších 5 km.</p>
            <StaticImage src="../assets/images/blog/camino/camino19.jpg" alt=""></StaticImage>
            <p>Těch posledních 5 kilometrů se mi zdálo nekonečných - rovinka podél silnice a nikde žádný stín. Mazala jsem se opalovacím krémem snad každých 10 minut, ale stejně mi přišlo, že se doslova smažím. Bylo mi jasné, že do Caminhi dorazím až kolem páté odpoledne, tudíž riskuji, že Albergue budou plné. Tak jsem si raději rezervovala na Bookingu postel v hostelu, abych měla jistotu, že budu mít, kde složit hlavu.</p>
            <p>Do hostelu jsem přišla úplně vyřízená - teklo ze mě, všechno mě bolelo a asi jsem byla přehřátá. Když mě zahlédl recepční, nabídl mi vodu, a ať se nejdřív usadím a vydechnu si. Prvních asi 15 minut jsem jen ležela na posteli s nohama nahoře, chtělo se mi brečet a nebyla jsem si jistá, jestli se ještě vůbec zvednu. Potřebovala jsem ale dát se dokupy a dojít si pro nějaké jídlo.</p>
            <StaticImage src="../assets/images/blog/camino/camino20.jpg" alt=""></StaticImage>
            <p>Cesta na náměstí trvala za běžných okolností asi 5 minut, já to šla 20 minut. Sedla jsem si v první restauraci, objednala si, a jen koukala a přemýšlela, jestli vůbec budu schopná ráno vstát.</p>
            <p>Po večeři jsem si na hostelu vzala v baru malou láhev vína a sedla si venku na lavičce, kde jsem si užívala samoty, klidu a pocit, že už sedím a jediná cesta, která mě ten večer čeká, je ta do postele.</p>
            <DoubleImage>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/camino/camino21.jpg" alt=""></StaticImage>
                </StyledFigure>
                <StyledFigure doubleImage={true}>
                    <StaticImage src="../assets/images/blog/camino/camino22.jpg" alt=""></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <SectionTitle>Den 6 - Už mě vlastně nic nebolí</SectionTitle>
            <p>Z Caminhi jsem se rozhodla pro možnost číslo dvě, což znamenalo pokračovat po portugalské straně. Dneska mě čekalo pouhých 23 kilometrů, což je oproti předchozím dnům úplný nic. Cesta vedla malebnými vesničkami a opět se neobešla bez stoupání a klesání. Stoupání bylo super v tom, že jsem se těšila opět na výhledy. Klesání už bylo méně zábavné.</p>
            <StaticImage src="../assets/images/blog/camino/camino23.jpg" alt=""></StaticImage>
            <p>Během poledne jsem dorazila do města Vila Nova de Cerviera, kde jsem se zastavila na oběd. Dopřála jsem si rybu se zeleninovou přílohou, aby mi nebylo nějak těžko po zbytek cesty. Ale to jsem nečekala, že sluníčko opět zatopí na 35 stupňů. Po vyšlápnutí kopce po obědě, jsem měla pocit, že každou chvíli vzplanu. Našla jsem si tedy první stín pod stromem u místního kostela a rozvalila se tam na zemi. Potřebovala jsem trochu vychladnout a volné chvilky jsem využila, abych si přes Booking rezervovala další spaní v hostelu (soukromé Albergue). Věděla jsem, že chci dojít do městečka Sao Pedro da Torre a nechci riskovat, že tam zase nic nenajdu a navíc, už mi nezbývala skoro žádná hotovost (v klasických Albergue se platí pouze hotově).</p>
            <StaticImage src="../assets/images/blog/camino/camino24.jpg" alt=""></StaticImage>
            <br />
            <br />
            <StaticImage src="../assets/images/blog/camino/camino25.jpg" alt=""></StaticImage>
            <p>Ležela jsem pod tím stromem a došla mi jedna zajímavá věc - uvědomila jsem si, že mě už vůbec nebolí nohy. JO! Konečně! Trvalo to celých 5 dní, aby si moje nohy a chodidla na tohle celé zvykly. Rozbrečela jsem se radosti… ten pocit, který jsem v tu chvíli měla, se nedá ani popsat slovy :)</p>
            <StaticImage src="../assets/images/blog/camino/camino26.jpg" alt=""></StaticImage>
            <p>Když jsem dorazila do Sao Pedro, ihned jsem zamířila do lékárny, kde jsem si pořídila krém po opalování na svoje spálené ruce a regenerační krém na chodidla (musím si je teď hýčkat :) ). Dorazila jsem na Albergue, které patřilo manželům Pedrovi a Anně. Majitelé byli naprosto skvělí - měla jsem pocit, že jsem dorazila do ráje. Ubytovali mě, ukázali mi, kde co je a kde mají bazén…Bazén?! Proboha, tak to jsem vážně v ráji.</p>
            <p>Na pokoji jsme se sešly 4 slečny - dvě Češky (já a Vendy) a dvě Němky (Charlie a Verena). Vendy, která byla z Brna mi tenkrát pomohla vyřešit problém s hotovosti, kterou jsem už vlastně neměla. Naštěstí zrovna ten den vybrala a tam mi půjčila peníze a já jí je obratem zaslala na účet. Problem solved.</p>
            <p>Zbytek večera jsme strávili všichni u jídla a vína ve společnosti Pedra a Anny, kde jsme si poslechly jejich příběh seznámení a založení Albergue.</p>
            <p>Tohle Albergue pro mě tenkrát mělo mnohem větší význam, než jsem si mohla vůbec představit. O jedné z mých spolubydlících na pokoji ještě uslyšíte, hádejte o které? :)</p>
        </Wrapper>
       </>
    )
  }

  export default BlogpostPage
